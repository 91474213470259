import * as React from 'react';
import { Link } from 'gatsby';
// markup
const NotFoundPage = () => {
  return <div id="notfound">
  <div className="notfound">
    <div className="notfound-404">
      <h1>404</h1>
    </div>
    <h2>¡Ups! no se encontró nada</h2>
    <p>Es posible que la página que está buscando haya sido eliminada debido a un cambio de nombre o no esté disponible temporalmente.
    <Link to="/">Return to homepage</Link>
    </p>
  </div>
</div>
};

export default NotFoundPage;
